<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b">
                    <el-button type="primary" size="small" @click="addCustomerShopAddress" v-right-code="'Customershopaddress:Add'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="deleteCustomerShopAddress" v-right-code="'Customershopaddress:Delete'">删除</el-button>
                </div>
            </div>
            <p slot="elList">
                <el-table ref="customerShopAddressListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='ShopNo'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Utils.lazy(() => {
                var _this = this;
                this.Event.$on("reloadCustomerShopAddressPageList", () => this.reloadPageList());
                this.Event.$on("onAddCustomerShopAddress", () => this.addCustomerShopAddress());
            });
            this.initialize();
        },
        data() {
            return {
                tUserOptions: {
                    visible: false
                },
                multipleSelection: [],
                isPassValidate: true,
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                importConfig: {
                    addressType: 0,
                    visible: false,
                    size: 'small',
                }
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/customershopaddress/search", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });


            },
            rowClick(row) {
                this.$ajax.send("omsapi/customershopaddress/details", "get", { id: row.CustomerShopAddressId }, (data) => {
                    this.Event.$emit("clearEditCustomerAddressForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            addCustomerShopAddress() {
                this.$ajax.send("omsapi/customershopaddress/empty?customerCode=", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            deleteCustomerShopAddress() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择店铺地址.`, "error");
                    return false;
                }
                this.Utils.confirm({
                    title: "是否删除地址?",
                    content: "删除后数据内容会发生改变,是否确认删除?"
                }, () => {
                    this.$ajax.send("omsapi/customershopaddress/delete", "get", {id:this.multipleSelection[0].CustomerShopAddressId}, (data) => {
                        this.onPageChange(this.queryParam);
                        this.Utils.messageBox("地址删除成功.", "success");
                    });
                }, () => {
                    this.Utils.messageBox("已取消删除.", "info");
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            }
        }

    }
</script>
<style>
</style>